<template>
    <div>
        <VModal
            :visible="!isReadCookiePolicy || !isReadPrivacyPolicy"
            title="ศูนย์การตั้งค่าความเป็นส่วนตัว"
            :closable="false"
            bottom>
            {{ desc }}
            <br>
            <br>
            <a-collapse>
                <a-collapse-panel key="1" header="คุกกี้ที่จำเป็น (Strictly Nescessary Cookies)">
                    <p>{{ strictlyCookieDesc }}</p>
                    <h3 slot="extra" style="color: orange">
                        เปิดใช้ตลอดเวลา
                    </h3>
                </a-collapse-panel>
            </a-collapse>
            <br>
            - <VLink
                href="https://lenkila.com/privacy-policy"
                label="นโยบายความเป็นส่วนตัว" />
            <br>
            - <VLink
                href="https://lenkila.com/terms-and-conditions"
                label="ข้อกำหนดและเงื่อนไข" />
            <br>
            <br>
            <a-button
                type="primary"
                html-type="submit"
                block
                @click="onAccept">
                ยอมรับ
            </a-button>
        </VModal>
    </div>
</template>
<script>
export default {}
</script>

<style>

</style>
<script>
import VModal from '@/components/VModal.vue'
import { rootActions } from '@/store/types'
import VLink from '@/components/VLink.vue'

export default {
    components: {
        VModal,
        VLink
    },
    data () {
        return {
            desc:`เมื่อคุณเข้าชมเว็บไซต์ใดก็ตาม ไซต์นั้นอาจจัดเก็บหรือดึงข้อมูลจากเบราว์เซอร์ของคุณ
 ส่วนใหญ่แล้ว อยู่ในรูปแบบของคุกกี้ ข้อมูลนี้อาจเกี่ยวกับคุณ การตั้งค่าของคุณ อุปกรณ์ของคุณ
 หรือเพื่อช่วยให้ ไชต์ทํางานอย่างที่คุณต้องการ ซึ่งมักเป็นข้อมูลที่ไม่สามารถระบุตัวตนของคุณได้โดยตรง
 แต่ช่วยให้คุณใช้งานเว็บตามความต้องการส่วนบุคคลได้มากยิ่งขึ้น
 คุณสามารถปฏิเสธไม่ให้คุกกี้บางประเภททํางานได้
 คลิกที่หัวข้อประเภทอื่นๆ เพื่อดูข้อมูลเพิ่มเติมและเปลี่ยนการตั้งค่าเริ่มต้นของเรา
 อย่างไรก็ตาม
 คุณควรทราบว่าการบล็อกคุกกี้บางประเภทอาจส่งผลต่อการใช้งานไซต์และบริการที่เรามีให้ใช้งาน`,
        strictlyCookieDesc:`คุกกี้ประเภทนี้มีความจำเป็นต่อการทำงานของเว็บไซต์
 เพื่อให้เว็บไซต์สามารถทำงานได้เป็นปกติ มีความปลอดภัย
 และทำให้ท่านสามารถเข้าใช้เว็บไซต์ได้ เช่น การ log in เข้าสู่เว็บไซต์ การยืนยันตัวตน
 ทั้งนี้ ท่านไม่สามารถปิดการใช้งานของคุกกี้ประเภทนี้ผ่านระบบของเว็บไซต์ของเราได้`
        }
    },
    created () {
        this.$store.dispatch(rootActions.CHECK_IS_READ_POLICY)
    },
    computed: {
        isReadCookiePolicy(){
            return this.$store.state.auth.isReadCookiePolicy;
        },
        isReadPrivacyPolicy(){
            return this.$store.state.auth.isReadPrivacyPolicy;
        }
    },
    methods: {
        onAccept() {
            this.$store.dispatch(rootActions.ACCEPT_POLICY)
        }
    },
}
</script>
