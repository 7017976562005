var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VModal",
        {
          attrs: {
            visible: !_vm.isReadCookiePolicy || !_vm.isReadPrivacyPolicy,
            title: "ศูนย์การตั้งค่าความเป็นส่วนตัว",
            closable: false,
            bottom: ""
          }
        },
        [
          _vm._v("\n        " + _vm._s(_vm.desc) + "\n        "),
          _c("br"),
          _c("br"),
          _c(
            "a-collapse",
            [
              _c(
                "a-collapse-panel",
                {
                  key: "1",
                  attrs: {
                    header: "คุกกี้ที่จำเป็น (Strictly Nescessary Cookies)"
                  }
                },
                [
                  _c("p", [_vm._v(_vm._s(_vm.strictlyCookieDesc))]),
                  _c(
                    "h3",
                    {
                      staticStyle: { color: "orange" },
                      attrs: { slot: "extra" },
                      slot: "extra"
                    },
                    [
                      _vm._v(
                        "\n                    เปิดใช้ตลอดเวลา\n                "
                      )
                    ]
                  )
                ]
              )
            ],
            1
          ),
          _c("br"),
          _vm._v("\n        - "),
          _c("VLink", {
            attrs: {
              href: "https://lenkila.com/privacy-policy",
              label: "นโยบายความเป็นส่วนตัว"
            }
          }),
          _c("br"),
          _vm._v("\n        - "),
          _c("VLink", {
            attrs: {
              href: "https://lenkila.com/terms-and-conditions",
              label: "ข้อกำหนดและเงื่อนไข"
            }
          }),
          _c("br"),
          _c("br"),
          _c(
            "a-button",
            {
              attrs: { type: "primary", "html-type": "submit", block: "" },
              on: { click: _vm.onAccept }
            },
            [_vm._v("\n            ยอมรับ\n        ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }