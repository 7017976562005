var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("CookiePolicyAnnoucement"),
      _c(
        "a-row",
        [
          _c(
            "a-col",
            {
              attrs: {
                sm: {
                  span: _vm.borderless ? 24 : 22,
                  offset: _vm.borderless ? 0 : 1
                },
                xxl: {
                  span: _vm.borderless ? 19 : 13,
                  offset: _vm.borderless ? 0 : 5
                }
              }
            },
            [
              _c(
                "div",
                {
                  staticClass: "vpage__container",
                  class: { "vpage__container--borderless": _vm.borderless }
                },
                [
                  _c(
                    "a-row",
                    [
                      _c(
                        "a-col",
                        { attrs: { span: 24 } },
                        [
                          _vm._t("title", function() {
                            return [
                              _vm.title !== ""
                                ? _c(
                                    "h1",
                                    {
                                      staticClass: "vpage__header",
                                      style: _vm.titleStyle
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.title) +
                                          "\n                            "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ],
                    1
                  ),
                  _c("a-row", [_vm._t("default")], 2)
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }