<template>
    <a
        class="vlink"
        :href="href"
        target="_blank"
        rel="noopener">{{ label }}</a>
</template>
<script>
export default {
    props: {
        label: {
            type: String,
            required: true,
        },
        href: {
            type: String,
            required: true,
        },
    },
}
</script>
<style lang="stylus">
.vlink
    text-decoration: underline;
    &:hover
        text-decoration: underline;
</style>
