<template>
    <a-modal
        :visible="visible"
        :title="title"
        width="90%"
        :centered="!bottom"
        :closable="closable"
        :dialog-style="bottom ? { position: 'fixed',top: 'auto', bottom: '20px' } : {}"
        :footer="null"
        destroy-on-close
        :after-close="afterClose"
        wrap-class-name="vmodal"
        @change="$emit('change', $event.target)">
        <slot />
    </a-modal>
</template>
<script>
export default {
    model: {
        prop: 'visible',
        event: 'change',
    },
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        bottom: {
            type: Boolean,
            default: false,
        },
        closable: {
            type: Boolean,
            default: true,
        },
        afterClose: {
            type: Function,
            default: () => ({}),
        },
    },
}
</script>

<style lang="stylus">
.vmodal
    padding-top 32px
    .ant-modal-content
        max-width 1080px
        margin 0 auto
</style>
