var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.visible,
        title: _vm.title,
        width: "90%",
        centered: !_vm.bottom,
        closable: _vm.closable,
        "dialog-style": _vm.bottom
          ? { position: "fixed", top: "auto", bottom: "20px" }
          : {},
        footer: null,
        "destroy-on-close": "",
        "after-close": _vm.afterClose,
        "wrap-class-name": "vmodal"
      },
      on: {
        change: function($event) {
          return _vm.$emit("change", $event.target)
        }
      }
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }