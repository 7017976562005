<template>
    <div>
        <CookiePolicyAnnoucement />
        <a-row>
            <a-col
                :sm="{ span: borderless ? 24 : 22, offset: borderless ? 0 : 1 }"
                :xxl="{ span: borderless ? 19 : 13, offset: borderless ? 0 : 5 }">
                <div class="vpage__container" :class="{ 'vpage__container--borderless': borderless }">
                    <a-row>
                        <a-col :span="24">
                            <slot name="title">
                                <h1
                                    v-if="title !== ''"
                                    class="vpage__header"
                                    :style="titleStyle">
                                    {{ title }}
                                </h1>
                            </slot>
                        </a-col>
                    </a-row>
                    <a-row>
                        <slot />
                    </a-row>
                </div>
            </a-col>
        </a-row>
    </div>
</template>
<script>
import CookiePolicyAnnoucement from '@/components/CookiePolicyAnnoucement.vue'

export default {
    components: {
        CookiePolicyAnnoucement,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        titleStyle: {
            type: Object,
            default: () => {},
        },
        requiredAuth: {
            type: Boolean,
            default: true,
        },
        borderless: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
<style lang="stylus">
.vpage__container
    padding: 32px
    background-color: #fff
    &.vpage__container--borderless
        padding: 0px
        .vpage__header
            margin-bottom: 0
.vpage__header
    margin-bottom: 52px

</style>
